import NiceModal from '@ebay/nice-modal-react'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { useAdminMode } from '@/lib/hooks/useAdminMode'

export const useModalData = () => {
  const activeModals = useContext(NiceModal.NiceModalContext)
  const areThereActiveModals = Object.keys(activeModals).length > 0
  const isAdminMode = useAdminMode()
  const router = useRouter()

  useEffect(() => {
    router.beforePopState(() => {
      // when a user uses browser navigation, this hook will go through each active modal and remove them using their id
      Object.values(activeModals).forEach(i => NiceModal.remove(i.id))

      // if this was return false, it would cancel the navigation request
      return true
    })
  }, [router])

  useEffect(() => {
    if (areThereActiveModals && !isAdminMode) {
      document.body.classList.add('stop-scroll')
    } else {
      document.body.classList.remove('stop-scroll')
    }

    return () => {
      document.body.classList.remove('stop-scroll')
    }
  }, [areThereActiveModals, isAdminMode])

  return areThereActiveModals
}
